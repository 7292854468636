import { RouteRecordRaw } from 'vue-router';
import PassThroughRoute from '@/components/PassThroughRoute.vue';

const routes: Array<RouteRecordRaw> = [
  {
    name: 'login',
    path: '/login',
    component: () => import('./pages/login.vue'),
  },
  /* fishlake */
  {
    name: 'fishlake',
    path: '/fishlake/:uuid?',
    props: true,
    component: () => import('./pages/fishLake/index.vue'),
    meta: { title: 'FishLake Page' },
    children: [
      /* when no sub path show employees page */
      {
        name: 'fishlake overview',
        path: 'overview',
        component: () => import('./pages/fishLake/Overview.vue'),
        meta: { title: 'FishLake Company Overview' },
      },
      {
        name: 'fishlake rubrics',
        path: 'rubrics',
        component: () => import('./pages/fishLake/Rubrics.vue'),
        meta: { title: 'FishLake Company Rubrics' },
      },
      {
        name: 'fishlake employees',
        path: 'employees',
        component: () => import('./pages/fishLake/Employees.vue'),
        meta: { title: 'FishLake Company Employees' },
      },
      {
        name: 'fishlake career syncs',
        path: 'career-sync',
        component: () => import('./pages/fishLake/OneOnOnes.vue'),
        meta: { title: 'FishLake Company Career Syncs' },
      },
      {
        name: 'fishlake competencies',
        path: 'competencies',
        component: () => import('./pages/fishLake/Competencies.vue'),
        meta: { title: 'FishLake Company Competencies' },
      },
    ],
  },
  /* app */
  {
    name: 'root',
    path: '/',
    component: () => import('./components/BasePage.vue'),
    children: [
      /* manager dashboard */
      {
        name: 'team',
        path: 'team',
        component: () => import('./pages/manager_dashboard/index.vue'),
        meta: { title: 'My Team' },
      },
      /* My Progress - employee -  subroute  */
      {
        name: 'employee',
        path: 'employee',
        meta: { background: 'default', title: 'Profile Page' },
        beforeEnter: (to, from) => {
          if (to.query.uuid) {
            if (from.name === 'company employees' || from.path.includes('analyze')) {
              return { name: 'company employee progress', query: { uuid: to.query.uuid } };
            }
            if (from.name === 'team') {
              return { name: 'team employee progress', query: { uuid: to.query.uuid } };
            }
          } return true;
        },
        component: () => import('./pages/employee/index.vue'),
      },
      {
        name: 'achievements',
        path: 'achievements',
        component: () => import('./pages/achievements/index.vue'),
        meta: { title: 'Achievements Page' },
        beforeEnter: (to, from) => {
          if (to.query.uuid) {
            if (from.name === 'company employee progress') {
              return {
                name: 'company employee achievements',
                query: { uuid: to.query.uuid },
              };
            }
            if (from.name === 'team employee progress') {
              return {
                name: 'team employee achievements',
                query: { uuid: to.query.uuid },
              };
            }
            return true;
          } return true;
        },
      },
      {
        name: 'feedback',
        props: true,
        path: 'feedback/:status?/:direction?',
        component: () => import('./pages/feedback/index.vue'),
        meta: { title: 'Feedback Page' },
        beforeEnter: (to, from) => {
          if (to.query.uuid) {
            if (from.name === 'company employee progress') {
              return {
                name: 'company employee feedback',
                query: {
                  uuid: to.query.uuid,
                  ...(to.query.item && { item: to.query.item }),
                },
              };
            }
            if (from.name === 'team employee progress') {
              return {
                name: 'team employee feedback',
                query: {
                  uuid: to.query.uuid,
                  ...(to.query.item && { item: to.query.item }),
                },
              };
            }
          } return true;
        },
      },
      {
        path: 'assessments',
        component: PassThroughRoute,
        meta: { breadcrumb: 'Assessments' },
        children: [
          {
            name: 'assessments',
            path: '',
            beforeEnter: (to, from) => {
              if (to.query.uuid) {
                if (from.name === 'company employees' || from.path.includes('analyze')) return { name: 'company employee progress', query: { uuid: to.query.uuid } };
                if (from.name === 'team') return { name: 'team employee progress', query: { uuid: to.query.uuid } };
              } return true;
            },
            component: () => import('./pages/assessments/index.vue'),
            meta: { title: 'Assessments Page' },
          },
          {
            name: 'assessment',
            path: ':reviewUuid',
            props: true,
            component: () => import('./pages/assessment/index.vue'),
            meta: {
              title: 'Assessment Page',
              breadcrumb: 'Assessment',
            },
            beforeEnter: (to, from) => {
              if (to.query.uuid) {
                if (from.name === 'company employee progress') {
                  return {
                    name: 'company employee assessment',
                    query: {
                      uuid: to.query.uuid,
                      ...(to.query['assessment-uuid'] && { 'assessment-uuid': to.query['assessment-uuid'] }),
                    },
                  };
                }
                if (from.name === 'team employee progress') {
                  return {
                    name: 'team employee assessment',
                    query: {
                      uuid: to.query.uuid,
                      ...(to.query['assessment-uuid'] && { 'assessment-uuid': to.query['assessment-uuid'] }),
                    },
                  };
                }
                return true;
              } return true;
            },
          },
        ],
        beforeEnter: (to, from) => {
          if (to.query.uuid && to.name !== 'assessment' && to.name !== 'calibration') {
            if (from.name === 'company employee progress' || from.name === 'company employee assessments') {
              return {
                name: 'company employee assessments',
                query: {
                  uuid: to.query.uuid,
                  ...(to.query['assessment-uuid'] && { 'assessment-uuid': to.query['assessment-uuid'] }),
                },
              };
            }
            if (from.name === 'team employee progress' || from.name === 'team employee assessments') {
              return {
                name: 'team employee assessments',
                query: {
                  uuid: to.query.uuid,
                  ...(to.query['assessment-uuid'] && { 'assessment-uuid': to.query['assessment-uuid'] }),
                },
              };
            }
            return true;
          } return true;
        },
      },
      {
        path: 'meetings',
        component: PassThroughRoute,
        meta: { breadcrumb: 'Meetings' },
        children: [
          {
            name: 'meetings',
            path: '',
            component: () => import('./pages/meetings/index.vue'),
            meta: { title: 'Meetings Page' },
          },
          {
            name: 'meeting',
            path: ':meetingUuid',
            props: true,
            component: () => import('./pages/meeting/index.vue'),
            meta: { title: 'Meeting Page' },
          },
        ],
      },
      {
        path: 'goals',
        name: 'Goals',
        component: () => import('./pages/goals/index.vue'),
        beforeEnter: (to, from) => {
          if (to.query.uuid) {
            if (from.name === 'company employee progress' || from.name === 'company employee goals') {
              return {
                name: 'company employee goals',
                query: { uuid: to.query.uuid, item_uuid: to.query.item_uuid },
              };
            }
            if (from.name === 'team employee progress' || from.name === 'team employee goals') {
              return {
                name: 'team employee goals',
                query: { uuid: to.query.uuid, item_uuid: to.query.item_uuid },
              };
            }
            return true;
          } return true;
        },

      },
      /* employee settings  */
      {
        name: 'employee settings',
        path: 'settings',
        component: () => import('./pages/settings/settings.vue'),
        meta: { title: 'Employee Settings Page' },
      },
      /* team */
      {
        path: 'team/employee',
        component: PassThroughRoute,
        meta: { breadcrumb: 'Employee Progress' },
        children: [
          {
            name: 'team employee progress',
            path: '',
            component: () => import('./pages/employee/index.vue'),
            meta: {
              background: 'peer',
              title: 'Employee Progress',
            },
          },
          {
            name: 'team employee feedback',
            path: 'feedback/:status?/:direction?',
            props: true,
            component: () => import('./pages/feedback/index.vue'),
            meta: {
              title: 'Feedback Page',
              breadcrumb: 'Feedback',
              background: 'peer',
            },
          },
          {
            name: 'team employee achievements',
            path: 'achievements',
            component: () => import('./pages/achievements/index.vue'),
            meta: {
              title: 'Achievement Page',
              breadcrumb: 'Achievements',
              background: 'peer',
            },
          },
          {
            path: 'assessments',
            component: PassThroughRoute,
            meta: {
              breadcrumb: 'Assessments',
              background: 'peer',
            },
            children: [
              {
                name: 'team employee assessments',
                path: '',
                component: () => import('./pages/assessments/index.vue'),
                meta: { title: 'Assessments Page', background: 'peer' },
              },
              {
                name: 'team employee assessment',
                path: ':reviewUuid',
                props: true,
                component: () => import('./pages/assessment/index.vue'),
                meta: {
                  title: 'Assessment Page',
                  breadcrumb: 'Assessment',
                  background: 'peer',
                },
              },
            ],
          },
          {
            name: 'team employee goals',
            path: 'goals',
            component: () => import('./pages/goals/index.vue'),
            meta: {
              title: 'Goals Page',
              background: 'peer',
              breadcrumb: 'Goals',
            },
          },
        ],
        beforeEnter: (to) => {
          if (!to.query.uuid) return { name: 'employee' };
          return true;
        },
      },
      /* rubric */
      {
        name: 'rubric',
        path: 'rubric/:name?/:role?/:level?/:competency?',
        props: true,
        component: () => import('./pages/rubric/index.vue'),
        meta: { title: 'Rubric Page', breadcrumb: 'Rubric' },
      },
      /* company subroute  */
      {
        path: 'company',
        meta: { breadcrumb: 'Admin' },
        redirect: 'company employees',
        component: () => import('./pages/company/index.vue'),
        children: [
          {

            name: 'company',
            path: 'employees',
            component: PassThroughRoute,
            meta: {
              title: 'Company Employees',
              breadcrumb: 'Employees',
            },
            children: [
              {
                name: 'company employees',
                path: '',
                component: () => import('./pages/company/Employees.vue'),
              },
              {
                path: 'employee',
                component: PassThroughRoute,
                meta: { title: 'Company Employee Progress', breadcrumb: 'Employee Progress' },
                children: [
                  {
                    name: 'company employee progress',
                    path: '',
                    component: () => import('./pages/employee/index.vue'),
                    meta: {
                      title: 'Feedback Page',
                      background: 'peer',
                    },
                  },
                  {
                    name: 'company employee feedback',
                    path: 'feedback/:status?/:direction?',
                    props: true,
                    component: () => import('./pages/feedback/index.vue'),
                    meta: {
                      title: 'Feedback Page',
                      breadcrumb: 'Feedback',
                      background: 'peer',
                    },
                  },
                  {
                    name: 'company employee achievements',
                    path: 'achievements',
                    component: () => import('./pages/achievements/index.vue'),
                    meta: {
                      title: 'Achievements Page',
                      breadcrumb: 'Achievements',
                      background: 'peer',
                    },
                  },
                  {
                    path: 'assessments',
                    component: PassThroughRoute,
                    meta: {
                      breadcrumb: 'Assessments',
                      background: 'peer',
                    },
                    children: [
                      {
                        name: 'company employee assessments',
                        path: '',
                        component: () => import('./pages/assessments/index.vue'),
                        meta: {
                          title: 'Assessments Page',
                          background: 'peer',
                        },
                      },
                      {
                        name: 'company employee assessment',
                        path: ':reviewUuid',
                        props: true,
                        component: () => import('./pages/assessment/index.vue'),
                        meta: {
                          title: 'Assessment Page',
                          breadcrumb: 'Assessment',
                          background: 'peer',
                        },
                      },
                    ],
                  },
                  {
                    name: 'company employee goals',
                    path: 'goals',
                    component: () => import('./pages/goals/index.vue'),
                    meta: {
                      title: 'Goals Page',
                      background: 'peer',
                      breadcrumb: 'Goals',
                    },
                  },
                ],
                beforeEnter: (to) => {
                  if (!to.query.uuid) return { name: 'employee' };
                  return true;
                },
              },
            ],
          },
          {
            name: 'company settings',
            path: 'settings',
            props: true,
            component: () => import('./pages/company/Settings.vue'),
            meta: {
              title: 'HRIS Settings Page',
              breadcrumb: 'Settings',
            },
          },
          {
            path: 'cycles',
            component: PassThroughRoute,
            meta: {
              title: 'Cycles Page',
              breadcrumb: 'Cycles',
            },
            children: [
              {
                name: 'company cycles',
                path: '',
                props: true,
                component: () => import('./pages/cycles/index.vue'),
              },
              {
                name: 'cycle form',
                path: 'form/:uuid?',
                props: true,
                component: () => import('./pages/cycles/CycleForm.vue'),
              },
              {
                path: '/cycles/:pathMatch(.*)',
                redirect: '/cycles',
              },
            ],
          },
        ],
      },
      /* analyze subroute  */
      {
        name: 'analytics',
        path: '/analyze',
        meta: { breadcrumb: 'Analyze' },
        component: () => import('./pages/analyze/index.vue'),
        redirect: '/',
        children: [
          /* when no sub path show employees page */
          {
            name: 'analyze activity',
            path: 'activity/:chart?',
            props: true,
            meta: { breadcrumb: 'Activity' },
            component: () => import('./pages/analyze/Activity.vue'),
          },
          {
            name: 'analyze performance',
            path: 'performance/:chart?',
            props: true,
            meta: { breadcrumb: 'Performance' },
            component: () => import('./pages/analyze/Performance.vue'),
          },
          {
            name: 'analyze compensation',
            path: 'compensation/:chart?',
            meta: { breadcrumb: 'Compensation' },
            props: true,
            component: () => import('./pages/analyze/Compensation.vue'),
          },
        ],
      },
      /* uplevel */
      {
        name: 'uplevel',
        path: 'employees/uplevel',
        component: () => import('./pages/uplevel.vue'),
        meta: { title: 'Uplevel Page' },
      },
      /* career sync */
      {
        path: '/career-sync',
        component: () => import('./pages/one_on_one/index.vue'),
        meta: { breadcrumb: 'Career Sync' },
        children: [
          {
            name: 'career sync dashboard',
            meta: { title: 'Career Sync' },
            path: '',
            component: () => import('./pages/one_on_one/OneOnOneDashboard.vue'),
          },
          {
            name: 'new career sync',
            path: 'new/:uuid?',
            meta: { breadcrumb: 'New Career Sync' },
            props: true,
            component: () => import('./pages/one_on_one/OneOnOneNew.vue'),
          },
          {
            name: 'career sync history',
            path: 'history/:employeeUuid/:meetingUuid?',
            props: true,
            meta: { breadcrumb: 'History' },
            component: () => import('./pages/one_on_one/OneOnOneHistory.vue'),
          },
          {
            path: '/career-sync/:pathMatch(.*)',
            redirect: '/career-sync',
          },
        ],
      },
      /* slack */
      {
        name: 'slack',
        path: 'slack',
        component: () => import('./pages/integrations/slack.vue'),
      },
      /* GCAL */
      {
        name: 'GCal',
        path: 'oauth2callback',
        component: () => import('./pages/integrations/GCal.vue'),
      },
      {
        path: 'calibration',
        redirect: (to) => ({
          name: 'calibration',
          query: {
            uuid: to.query.uuid,
            ...(to.query['base-assessment-uuid'] && { 'base-assessment-uuid': to.query['base-assessment-uuid'] }),
            ...(to.query['other-assessment-uuid'] && { 'other-assessment-uuid': to.query['other-assessment-uuid'] }),
            ...(to.query['review-uuid'] && { 'review-uuid': to.query['review-uuid'] }),
          },
        }),
      },
      { path: 'align/rubric/:pathMatch(.*)*', redirect: (to) => ({ name: 'rubric', params: { name: to.params.pathMatch[0], role: to.params.pathMatch[1], level: to.params.pathMatch[2], competency: to.params.pathMatch[3] } }) },
      { path: 'feedback', redirect: () => ({ name: 'feedback' }) },
      { path: 'feedback/:pathMatch(.*)*', redirect: (to) => ({ name: 'feedback', params: { status: to.params.pathMatch[0], direction: to.params.pathMatch[1] } }) },
      { path: 'assessment', redirect: () => ({ name: 'assessments' }) },
      { path: 'assessments', redirect: () => ({ name: 'assessments' }) },
      { path: 'assessments/calibration', redirect: (to) => ({ name: 'assessment', params: { reviewUuid: to.query['review-uuid'] as string | undefined || 'undefined' }, query: undefined }) },
      { path: 'company-settings', redirect: () => ({ name: 'company settings' }) },
      { path: 'manager', redirect: () => ({ name: 'team' }) },
      { path: 'lead', redirect: (to) => ({ name: 'team', params: to.params, query: to.query }) },
      { path: 'lead/employee/feedback', redirect: (to) => ({ name: 'team employee feedback', params: to.params, query: to.query }) },
      { path: 'lead/employee/achievements', redirect: (to) => ({ name: 'team employee achievements', params: to.params, query: to.query }) },
      { path: 'lead/employee/assessments', redirect: (to) => ({ name: 'team employee assessments', params: to.params, query: to.query }) },
      { path: 'lead/employee/assessments/assessment', redirect: (to) => ({ name: 'team employee assessment', params: to.params, query: to.query }) },
      { path: 'team/employee/assessments/calibration', redirect: (to) => ({ name: 'team employee assessment calibration', params: to.params, query: to.query }) },
      { path: 'team/employee/actions', redirect: () => ({ name: 'Goals' }) },
      { path: 'achievements', redirect: () => ({ name: 'achievements' }) },
      { path: 'company', redirect: () => ({ name: 'company employees' }) },
      { path: 'company/activity', redirect: () => ({ name: 'analyze activity' }) },
      { path: 'company/performance', redirect: () => ({ name: 'analyze performance' }) },
      { path: 'company/compensation', redirect: () => ({ name: 'analyze compensation' }) },
      { path: 'employee-settings/', redirect: () => ({ name: 'employee settings' }) },
      { path: 'align', redirect: () => ({ name: 'rubric' }) },
      { path: 'analyze', redirect: () => ({ name: 'analyze activity' }) },
      { path: 'employees/:uuid/uplevel', redirect: (to) => ({ name: 'uplevel', query: { uuid: to.params.uuid, 'uplevel-uuid': to.query.uuid } }) },
      { path: 'employee/feedback', redirect: () => ({ name: 'feedback' }) },
      { path: 'employee/feedback/:pathMatch(.*)*', redirect: (to) => ({ name: 'feedback', params: { status: to.params.pathMatch[0], direction: to.params.pathMatch[1] } }) },
      { path: 'employee/assessments/assessment', redirect: (to) => ({ name: 'assessment', query: { uuid: to.query.uuid, 'assessment-uuid': to.query['assessment-uuid'] } }) },
      { path: 'employee/assessments', redirect: () => ({ name: 'assessments' }) },
      { path: 'employee/goals', redirect: () => ({ name: 'Goals' }) },
      { path: 'employee/goals/actions/:pathMatch(.*)*', redirect: (to) => ({ name: 'Goals', query: { action_uuid: to.params.pathMatch[0] } }) },
      { path: 'employee/goals/:pathMatch(.*)*', redirect: (to) => ({ name: 'Goals', query: { goal_uuid: to.params.pathMatch[0] } }) },
      { path: 'employee/achievements', redirect: () => ({ name: 'achievements' }) },
    ],
  },
  // redirect for unmatched routes
  { path: '/:pathMatch(.*)*', redirect: '/employee' },
];

export default routes;
